import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import { H5, TextSm } from '@system'

const useStyles = makeStyles((theme) => ({
  image: {
    '& .gatsby-image-wrapper ': {
      '&>div': {
        [theme.breakpoints.down('sm')]: {
          paddingBottom: '30px !important',
        },
        [theme.breakpoints.up('sm')]: {
          paddingBottom: '60px !important',
        },
      },
      '& img': {
        objectFit: 'contain',
        objectPosition: 'left',
      },
    },
  },
  name: (props) => ({
    color:
      !!props.color && !!props.color.color
        ? props.color.color
        : theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
      paddingBottom: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '40px',
      paddingBottom: '10px',
    },
    display: 'block',
  }),
  productLogo: (props) => ({
    display: 'block',
    '& h3': {
      color: !!props.color && !!props.color.color && props.color.color,
    },
  }),
  description: (props) => ({
    color:
      !!props.color && !!props.color.color
        ? props.color.color
        : theme.palette.text.tertiary,
    paddingBottom: '25px',
    display: 'block',
  }),
  link: {
    marginTop: 'auto',
    textTransform: 'uppercase',
    '& a': {
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    },
  },
  linkContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '13px',

    '& a': {
      width: 'fit-content',
      marginBottom: '5px',
    },
    '& :last-child': {
      marginBottom: '0px',
    },
  },
  ctaContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '24px',
    justifyContent: 'flex-start',
  },
}))

const ProductCard = (props) => {
  const { image, name, productLogo, description, button, secondaryCta } =
    props.blok
  const classes = useStyles(props.blok)
  const secondaryCTA = !!secondaryCta && secondaryCta.length > 0
  return (
    <SbEditable content={props.blok}>
      {image && (
        <Box className={classes.image}>
          {renderBloks(image, {
            style: { objectFit: 'contain', width: 'auto', height: '100%' },
          })}
        </Box>
      )}
      {name && <H5 className={classes.name}>{name}</H5>}
      {productLogo && (
        <Box className={classes.productLogo}>
          {renderBloks(productLogo, {
            isProductCard: true,
            foregroundColor: props.foregroundColor,
          })}
        </Box>
      )}
      <Box>
        {description && (
          <TextSm className={classes.description}>{description}</TextSm>
        )}
        <Box className={classes.ctaContainer}>
          <Typography
            component="span"
            display="block"
            variant="button"
            className={classes.link}
          >
            {renderBloks(button)}
          </Typography>
          {secondaryCTA && (
            <Box className={classes.linkContainer}>
              {renderBloks(secondaryCta)}
            </Box>
          )}
        </Box>
      </Box>
    </SbEditable>
  )
}

export default ProductCard
